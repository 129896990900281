// import { upload } from "@testing-library/user-event/dist/upload";
// import Newsletter from "./components/Communication/Newsletter";
// import Participants from "./components/Participants";
// import { Lia500Px } from "react-icons/lia";

const string = {
  // banner
  bannerline: "Guaranteed B2G & B2B Meetings ",

  // register
  registertitle: "Register",
  registertext:
    "Schedule up to 30 qualitative and targeted meetings over the 2 days!  Build your agenda of B2B and B2G meetings with decision-makers of the aerospace  and defence sector, pre-select your contacts with the aid of the online catalogue.",

  // registerdone
  regi1: "An efficient way to establish new contacts.",
  regi2: "A time-efficient communication tool of B2B meetings.",
  regi3:
    "An opportunity to target companies corresponding to your specific needs.",
  regi4: "Describe your offers for other participants.",
  regi5: "Mention your needs and purpose of seeking meetings.",

  registertext2:
    "A very targeted and qualitative event: Pre-arranged face-to-face meetings (B2B  meetings) are the most efficient way to establish new contacts, with the opportunity  to assist in briefings on business opportunities by large groups and institutional  organisations, conferences on aerospace perspectives and market trends.",
  registertext3:
    "You can also register for Central Talks, Workshops and Flash Presentations without any  additional fee.",

  // code of conduct
  conduct:
    "If I can no longer attend, the event at any time, or be replaced by a suitable colleague, I  agree to inform the organizers of the DEFENCE PARTNERSHIP DAYS before 1 August  2014, in order for the other participants can arrange new meetings.",
  conduct2: "No refunds are possible after 1 June 2024.",
  read: "I have read the:",
  point1: "Terms and Conditions",
  point2: "Privacy Policy",

  // select category
  selectcetegorytitle: "Please select the category of registration:",

  categorybutton: "Go",
  categoryformtitle:
    "You are registering as Manufacturer/ Supplier/ Services Provider",
  categorytitle1: "Title:",
  categorytitle2: "Name:",
  categorytitle3: "Company:",
  categorytitle4: "Email:",
  categorytitle5: "Mobile No.",
  categorysubmit: "Next",

  // pop-up
  startup_pop: "Start-ups",
  startup_content1:
    "Start-ups benefit from concessional rates for participation. To qualify as a start-up, you must have obtained approval from DIPP and DIPP Start-up Regn Number.",
  startup_content2:
    "To qualify as a start-up, you must meet the following criteria:",
  startup_array: [
    "Registered as a Private Company, LLP or Partnership Firm.",
    "Must not be a product of restructuring. ",
    "Must not be older than 5 year, ie, est after 1 April 2019. ",
    "Annual turnover must not be more Rs. 25 crores. ",
    "The business must be involved in a new product or service. ",
    "Must have obtained approval from DIPP that the business is innovative",
  ],
  investors_pop: "Investors",
  investors_content:
    "Potentional investors, venture capitalists, entreprenuers who wish to stay on top of the latest trends and developments and to connect with potential partners, collaborators or get feedback on their business ideas or products. Investors will have full entitlement to fix 1-to-1 meetings.",
  associate_pop: "Associate/ Media Partner",
  associate_content:
    "Organisations wishing to partner with IMR may register and complete their profile and mention your purpose and expectations. IMR will get in touch to follow up.",
  sponsor_pop: "Sponsor",
  sponsor_content:
    "Any company that qualifies as a Prime Contractor/OEM, Manufacturer/Supplier/Services Provider, or Investor may seek Sponsorship.",
  casual_pop: "Casual Visitor",
  casual_content:
    "Industry executives, entreprenuers, academics, scholars, can register to:",
  casual_array: [
    "Attend workshops, central talks, flash presentations.",
    "Visit exhibition stands. ",
    "Fix casual meetings with participants who are free at their exhibition stands",
    "Refreshments and lunch are included..",
  ],
  // association_pop:"ASSOCIATION SPONSOR",
  // association_content:'Any Industry Association, which seeks to see4k benefits for its member countries may contact IMR for options, benefits and costs',
  primecontractor_pop: "Prime Contractor/ OEM",
  primecontractor_content1:
    "The event is free for one participant of Prime Contractors/ OEMs with entitlement to fix 10 meetings.",
  primecontractor_content2:
    "Prime Contractors/ OEMs will be invited by the organizers. Others who meet the following criteria may apply under this category, subject to approval:",
  primecontractor_array: [
    "Listed/unlisted DPSUs. ",
    "Manufacturer or system integrator of complete systems and not a trading company.",
    "Turnover ",
    "Net worth",
    "Holder of Defence Industrial Licence.",
    "Maintaining an Indian Supply Chain. ",
    "Foreign companies and JVs companies can apply.",
  ],
  manufacturer_pop: "Manufacturer/ Supplier/ Services Provider",
  manufacturer_content1:
    "Manufacturers, suppliers, services providers, whether registered/or not with Dept of Defence, DPSUs, DRDO, DGQA or not and wishing to expand the boundaries of their business in Defence or seeking to eneter Defence business and also seeking to establish a supply chain relationship with Prime Contractors and OEMs may exercise this option with full entitlement to fix meetings.",
  manufacturer_content2:
    "Other options include having your own exhibition stand, sposnorship, flash presentations and so on.",
  keygovt_pop: "Key Govt/ Services Officers",
  keygovt_content1: "These officers will be invited by the Organizers.",
  keygovt_content2: "They include officers dealing with:",
  ketgovt_array: [
    "Acquisition ",
    "Aerospace, Land and Maritime Systems ",
    "Technical Managers ",
    "DPSUs, New Defence Companies ",
    "Defence Exports ",
    "Indigenisation, Positive Lists ",
    "Offsets Management ",
    "iDEX, Innovation ",
    "Nodal Officers for Make I, II, III projects ",
    "Revenue procurement ",
    "Capability development ",
    "Staff Requirements, GSQRs ",
    "Design Bureaus ",
    "Capability Development ",
    "Technology Development Fund ",
    "Financial Planning ",
    "Long Term Procurement Plans ",
    "Revenue procurement ",
    "Problem Statements ",
    "Nodal officers for capital schemes ",
    "Army Commanders Special Financial Powers",
  ],
  Company_List_pop: "Company Listings Only",
  Company_List_content:
    "This option is for Companies which are unable to send delegates or participate physically but wish to have their profile included in the Defence Industry Directory to be released after the event for use by Ministry of Defence and Armed Forces procurement and acquisition officers.",

  // footer
  footer1title: "ABOUT",
  footer1point1: "CDS Message",
  footer1point2: "Who Participates",
  footer1point3: "Objectives & Key Benefits",
  // footer1point4: "Organizers",
  footer1point5: "How It Works",
  footer1point6: "Participants & Partners",
  footer1point7: "Newsletter",
  footer1point8: "Exhibition Booths And Meeting Points",
  footer1point9: "Terms & Conditions",
  footer1point10: "News",
  footer1point11: "Venue",

  footer2title: "PARTICIPANT PROFILES",
  footer2point1: "Key Govt & Services Officers",
  footer2point2: "Sponsors",
  footer2point3: "Prime Contractors & OEMs",
  footer2point4: "Manufacturers & Suppliers",
  footer2point5: "Startups",
  footer2point6: "Investors",
  footer2point7: "Associates and Media Partners",

  footer3title: "SCHEDULE",
  footer3point1: "Full schedule",
  footer3point2: "Presentations & Workshops",
  footer3point3: "Sponsors    ",
  footer3point4: " Associate Partners",
  footer3point5: "Industry Partners",
  footer3point6: "Media Partners",

  footer4title: "PROGRAM",
  footer4point1: "Program",
  footer4point2: "Central Talks",
  footer4point3: "Workshops",
  footer4point4: "Presentations",
  footer4point5: "Participants Guidelines",

  footer5title: " PRICING",
  footer5point1: "Meetings Package ",
  footer5point2: "All Packages/ Options",
  footer5point3: "Sponsorship Options  ",
  footer5point4: " Exhibition Options ",
  footer5point5: "Branding Options",
  footer5point6: "Communication Options",
  footer5point7: "Download Company Brochures",
  footer5point8: "Download Presentations",

  footer6title: "MEMBER'S AREA",
  footer6point1: "Log In/ My Profile",
  footer6point2: "Guidelines for Participants",
  footer6point3: "Request Meetings",
  footer6point4: "Download Company Brochures",
  footer6point5: "Download Presentations",
  // footer6point6: "Copyright",
  // footer6point7: "Communication Option",
  // footer6point8: "Casual Visitor’s Pass",

  FAQstitle: "Frequently Asked Questions",
  quastion1: "About the Event",
  answer1:
    "Defence Patnership Days 2024 is the leading B2B & B2G Defence Summit in India, dedicated to bring industry and users together face-to-face for one-to-one pre-scheduled meetings  for mutual benefit and better business prospects.",
  quastion2: "Why is it useful to attend?",
  answer21:
    "During Defence Partnership Days we facilitate meetings between Ptrime Contractors/OEMs, Manufacturers/ Suppliers/ Services Providers, Start-ups, and Investors on the one hand  and, Government & Services officer dealing with procurement, Prime Contractors and OEMs, DPSUs and new defence companies (OFB) on the other. Alongside, manufacrurers will  exhibit products and make presentations. The event will generate awareness on new technological innovations, access information, and give fillip to the cooperation and the  development of new partnerships.",
  answer22:
    "Due to the careful selection of participants, the online tools available for the event, the preparation, the invitation of participants and the planning of meetings before the event, every  participant has the possibility to optimize his program in order to maximize the productivity of meetings and his participation in the event.",
  quastion3: " Who are the participants to the event?",
  answer3a: "Prime Contractors/OEMs  ",
  answer3b: "Manufacturers/Suppliers/Services  ",
  answer3c: "Start-ups  ",
  answer3d: "Key Govt/ Services Officers  ",
  answer3e: "Casual Visitors  ",
  answer3f: "Investor  Event Partners/ Sponsors  ",
  answer3g: "Participants are listed on this page with their profiles....  ",
  answer3h: `Prime Contractors, OEMs and Manufacturers may be "buyers" for Tier 2, Tier-3 suppliers and "Sellers" for MoD.  Start ups are companies which wer established after 1 April 2019, with turnover less than Rs 25 crore.`,
  quastion4: "What is the criteria for Start-ups?",
  answer4a:
    "a. The start-up must be registered as a Private Company, LLP or Partnership Firm.  ",
  answer4b: "b. The start-up must not be a product of restructuring.  ",
  answer4c: "c. The Startup must not be older than 5 years.  ",
  answer4d:
    "d. Annual turnover of the start-up must not be more Rs. 25 crores.  ",
  answer4e: "e. The business must be involved in a new product or service.  ",
  answer4f:
    "f. The start-up must have obtained approval from DIPP that the business is innovative",

  quastion5: "When and where will the event take place?",
  answer5a: " The event will tae place over two days - 19 & 20 Aug 2024.  ",
  answer5b: "The venue is Manekshaw Centre, New Delhi.",

  quastion6: "How to register?",
  answer6a:
    "We recommend you the online registration at the following address: https://.........  ",
  answer6b:
    "Please select the Registration button and follow the steps described for online registration.  ",
  answer6c:
    "You will be asked to select the category of participation. After verifying your email and mobile number, you will be asked to provide information to complete your profile. The following  information will be required to complete your profile:  ",
  answer6d1: "a. Contact details and photo of participants.  ",
  answer6d2: "b. Company logo, information and financials.  ",
  answer6d3: "c. Main business category  ",
  answer6d4: "d. Products and services offered.  ",
  answer6d5: "e. Needs and purpose of participation.  ",
  answer6d6: "f.  Company's corpoarte video.  ",
  answer6d7: "g. Company literature.  ",
  answer6e:
    "The number of visits and requests for meetings depends upon the quality of profile.",

  quastion7: " Why is it important to register as soon as possible?",
  answer7:
    "Your early registration gives you the possibility to better optimize your participation, your meetings and your programme at the event. You can also ensure that the other participants  are available to meet you",

  quastion8: "What services do we offer during the event?",
  answer81:
    "The following services are free for all registered participants (except Casual Visitots) who are entitled to fix up meetings:  ",
  answer8a:
    "a.  Up to 30 pre-scheduled meetings over two days subject to acceptance of requests by interlocutors.  ",
  answer8b: "b.  Attendance at workshops, central talks, flash presentations. ",
  answer8c: "c.  Refreshments and lunch.  ",
  answer82:
    "All Govt/ Services Key Officers, Prime Contractors/ OEMs will be provided with a numbered Meeting Table, where all their meetings will be held.  ",
  answer83: "Participanting companies may also opt for extras like:  ",
  answer83a: "a. Sponsorships.  ",
  answer83b: "b. Exhibition Stands.  ",
  answer83c: "c. Workshop slots,  ",
  answer83d: "d. Flash presentations.  ",
  answer84:
    "Participants who opt for their own exhibition stands can hold meetings requested by others, at their own stand.",

  quastion9: "What benefits are available to Casual Visitors?",
  answer9a:
    "a.  Attendance at workshops, central talks, flash presentations.  ",
  answer9b: "b.  Refreshments and lunch.  ",
  answer9c: "c.  Visit exhibition stands.  ",
  answer9d:
    "d.  Fix casual meetings with participants who are free at their exhibition stands.  ",
  answer91:
    "Other services (additional exhibition materials, LED display monitors, flex and vinyl banner printing) can be provided on request. For items and rates see this page...  ",
  answer92:
    "It is very important to specify these requests upon your registration and latest by 1 Aug 2024.",

  quastion10: "What are the costs for participation?",
  answer101: "Registration is compulsory. Visit this link ....  ",
  answer102:
    "Passes for Casual Visitors are Rs 5900 (GST included). Casual visitors do not have the privilege of fixing pres-scheduled meetigs. The can meet participating companies at their  exhibition stands and impromptu depending upon availability.  Passes for others are included in their packages. Fopr details of pacakges visit this page....  ",
  answer103:
    "Participating companies can invite casual visitors to the event at a concessional rate of Rs 3540 (GST included). The link for invitation and registration will be provided to the  participating companies.",

  quastion11: "How can meetings be planned and scheduled?",
  answer111:
    "Every participant entitled to schedule 30 meetings over two days will be provided with unique login details.  ",
  answer112:
    "The Meetings Planner module is part of B2B Meetings services. You have access to this module only if you have bought a packsge which entitles you to schedule meetings.  ",
  answer113:
    "By using the Meetings Planner module, you can send online invitations for meetings to the registered participants in the event. The Planner module has 20 min slots.  ",
  answer114: `After logging in, view your planner. Mark the slots for which you will not be available. Use the button "Mark Unavailability" and select the time slot and mention reasons in comments.  You may be unavailable for some slots due to personal reasons or for attending a parallel workshop of Central Talk.`,
  answer115: `To request a meeting, first visit the profiles page of participants and decide whom to request for a meeting. You can determine the best time slot by matching the time slot available to  you and the other participant.  Use the button "Request Meeting" on your Planner module page.  `,
  answer116: `The invited person will receive an invitation in their account and on their email address. In case the participant has enable automatic acceptance, you will receive confirmation of the  meeting automatically. Else, you will receive the confirmation once the other participant manually approves your request.`,

  quastion12: " How to approve or refuse a meeting?",
  answer121: "You will receive an invitation on your email address.  ",
  answer122:
    "Please follow the instructions from the email and log into your account at the following address: https://....  Select and approve or refuse the request.",

  quastion13:
    " How to attend a Workshop or Flash Presentation or Central Talk?",
  answer13: `Select the Workshop, Flash Presentation or Central Talk that you wish to register for on the Planner module  and click on it. In the pop up select "Attend". Your name will be registered and your engagement will appear in your planner.`,

  quastion14: "Next Events",
  answer141:
    "We are always at your service, with fairs and B2B meetings events, built for your success.  ",
  answer142:
    "You can see which the following events are and when they will take place at https:/www.showcase.imrmedia.in",

  // terms & condition
  tctitle: "Terms & Conditions",
  tcpara:
    "The DEFENCE PARTNERSHIP DAYS 2024 event organized by IMR Media Pvt Ltd (Organizer) will take place at the Manekshaw Centre, New Delhi on 28-29 Nov 2024. In case of circumstances beyond the control of the Organizers (referred to as force majeure), the Organizer may choose to change the dates or the venue of this event or cancel it after notifying the participants.",

  // centraltalks
  centraltitle: "Central Talks",
  centralbluebg1:
    "See the schedule of Central Talks, Presentations and Workshops on the ",
  centralbluebg2: ". You can also enrol to attend any of them",
  centralbluebtn: "Schedule page",

  // key
  keytitle: "KEY GOVERNMENT AND SERVICES OFFICERS",

  // objective
  objtitle: " OBJECTIVES AND KEY BENEFITS",

  // HOW IT WORK
  howitworktitle: "BILATERAL MEETINGS - HOW IT WORKS",

  hiwpoint1: "1. ONLINE REGISTRATION",
  hiwcontent1:
    "Register online and submit your own business profile, stating what kind of technology/  product/expertise you are offering, what kind of cooperation you are looking for and which  ideas you would like to discuss with potential collaboration partners. All corporate profiles  will be published online and will be for everyone to see and seek meetings with you.",
  hiwpoint2: "2. PROMOTION OF PUBLISHED PROFILES",
  hiwcontent2:
    "All published profiles will be extensively promoted by the organisers of the event.  Furthermore, all participants of the event will view your corporate profile. A statistical  analysis of 40 matchmaking events showed that high quality profiles are visited about  50 to 150 times BEFORE the event and will still be viewed after the event.",
  hiwpoint3: "3. SELECTION OF BILATERAL MEETINGS",
  hiwcontent3: `You will be informed by email when you can start booking bilateral meetings. Check out  which corporate profiles are most promising. Intelligent search options allow a quick  identification of the most suitable participants/corporate profiles. Within a few minutes  you should be able to identify future potential business opportunities.Bookings are  managed on the principle "first come - first served". You can book meetings BUT you can  also be booked for bilateral talks by other participants!`,
  hiwpoint4: "4.  At the event",
  hiwcontent4:
    "A few days before the event you will receive your personal meeting schedule with the time,  the number of the table and who you are going to meet. At the registration desk, you will  receive an updated version of your meeting schedule including last minute bookings. Our  Help Desk staff will be at your disposal during the whole matchmaking event. When you  are not having a meeting there will be other flash presentations to attend. Refreshments  will be available throughout the day.",

  // schedule
  scheduletitle: "Schedule",
  subscheduletitle: "Central Talks, Presentations and Workshops",
  subschedule1: "Central Talks",
  subschedule1content:
    "At the begining of both days, and thereafter during Parallel Sessions, central talks are planned  for the benefit of all. For details please go to Central Talks.",
  subschedule2: "Presentations",
  subschedule2content:
    "Presentations will be made by Subject Matter Experts during the Parallel Sessions. These experts  are from the Ministry of Defence and its Departments, DRDO, Armed Forces and Corporate world.  For details please go to Flash Presentations.",
  subschedule3: "Workshops",
  subschedule3content:
    "Workshops will be conducted by Subject Matter Experts during the Parallel Sessions. These experts  are from the Ministry of Defence and its Departments, DRDO, Armed Forces and Corporate world.  For details please go to Workshops.",

  exhibitiontitle: " EXHIBITION BOOTHS AND MEETING POINTS",
  exhibitionheading:
    "The Meetings, Exhibition, Central Talks, Presentations and Workshops will all be held at the Manekshaw  Centre as follows:",
  exregi: "Registration:",
  exregicontent: " Main Foyer",
  exin: "Inaugural Address: ",
  exincontent: "Zorawar Hall (capacity 800)",
  exmee: "Meetings:",
  exmee_array: [
    `Bilateral Meetings will be held at numbered Exhibition Stands and at numbered Meeting Tables in the Foyer, Bullet Area, Upper Exhibition Hall, Lower Exhibition Hall and Reception Area.`,
    "Participating companies which have opted for Sponsorship or purchased Exhibition Stands will hold  meetings requested by others, at their own Exhibition Stands.  ",
    "Participants who have requested for meetings with other participants will go to others’ meeting tables.",
  ],
  expre: "Presentations and Workshops:",
  exprecontent:
    "Presentations and workshops will be conducted in Ashoka Hall or Shamsher Conference Room as will be  indicated in the Schedule.",
  exref: "Refreshments:",
  exrefcontent: [
    "Welcome Tea will be available in the Banquest Assembly near the Regn Desks. ",
    "High Tea will be laid out in the Banquet Hall.  ",
    "Lunch will be served in the Banquet Hall.  ",
    "Evening Tea will be available in the Foyer in front of Ashoka Hall.",
  ],
  exexe: "Executive Lounge:",
  exexecontent:
    "Tea/coffee will be available whole day in the Executive Lounge",

  exblueline:
    "The layout of exhibition stands and meetings tables is shown below:",

  // participants
  Participantstitle: "WHY PARTICIPATE?",

  // Home
  partytitle1: "Participating Companies",
  partytitle2: "Pre-scheduled One to one meetings",
  partytitle3: "MoD/armed forces key officers & OEMs",
  partyheadline:
    "Guaranteed Face-to-Face and 1-to-1 confirmed pre-scheduled meetings",
  partyparamain:
    "On 28-29 November 2024, Prime Contractors, OEMs, Manufacturers, Suppliers, Service Providers, MSMEs, Start-ups, Industry Associations and Investors will gather at the Manekshaw Centre, New Delhi to interact amongst themselves and primarily to meet key MoD and Service HQ nodal and project officers dealing with procurement, indigenisation, Make 1, Make 2, Import Substitution, Problem  Statements, GSQRs, RFIs, RFPs and design & development to seek and to offer solutions to strengthen the capabilities of the armed forces and also to expand the boundaries of their own businesses. Come, be a part of this unique opportunity, even if you have not ventured into the defence business. Your contribution to the nation’s security can bring satisfaction like no other.",
  partyquotemessage: "Message",
  partyquotespan: "from",
  partydesignation1: "The Chief of Defence Staff",
  partydesignation2: "Secretary Defence R&D & Chairman DRDO",
  partyquoteA1:
    "I am happy to learn that the Centre for Joint Warfare Studies (CENJOWS) and Indian Military Review (IMR) are organising 'Defence Partnership Days' on 28-29 Nov 2024 with an aim to support the nation's Atmanirbharta initiatives and to provide a credible platform for deliberations amongst the various stakeholders from the industry, MoD and the Armed Forces.",
  partyquoteB1:
    "DRDO is glad to be associated with Defence Partnership Days being organized by the Centre for Joint Warfare Studies and Indian Military Review (IMR) on 28-29 Nov 2024, to bring Industry and the Services together for face-to-face meetings. DRDO will also organize talks and provide officers for interaction.",
  partyquoteA2:
    "I wish the event unprecedented success. I sincerely hope that the event will witness huge participation from all the stakeholders including private industries, public enterprises, MSMEs, Start-ups and budding entrepreneurs.",
  partyquoteA3:
    "I am sanguine that the program would equally evoke a sense of opportunity, collaboration, competitiveness to achieve gold standards, as well as, responsibility among all the stakeholders of our defence ecosystem as we march forward together towards fostering a stronger, resurgent and 'Atmanirbhar' Bharat.",
  partyquoteB2:
    "The new format of the event should help industry for closer understanding of each other’s requirements and identifying areas of cooperation. I wish CENJOWS success in their endeavours.",
  partyquoteA4: "Jay Hind !",
  partyname1: "- Gen Anil Chauhan,",
  partyname2: "- Dr Samir V Kamat,",
  partyroles1: "PVSM, UYSM, AVSM, VSM, Chief of Defence Staff",
  partyroles2: "Secretary Defence R&D & Chairman DRDO",
  majorparty: "MAJOR PARTICIPANTS",
  partyheading1: "Unique-Effective-Profitable",
  partyprofit1:
    "Networking Blitz  with MoD, armed  forces procurement officers",
  partyprofit2: "Direct Access to  Decision Makers",
  partyprofit3: "Connect with Prime Contractors & OEMs  looking for vendors",
  partyprofit4:
    "Exclusive 1-to-1 meetings with your chosen executives  every 20 mins. 30 in 2 days",
  partyheading2: "SCHEDULE MEETINGS IN MINUTES",
  partyminutedate1: "25 July onwards",
  partyminutedate2: "15 August onwards",
  partyminutedate3: "25 November",
  partyminutedate4: "28-29 November",
  partyminute1a: "Registration.",
  partyminute1b: "Publish your business profile, needs & collaboration wishes",
  partyminute2: "Book B2G and B2B meetings",
  partyminute3: "Get Your Personal meeting schedule",
  partyminute4: "Bilateral face-2-face meetings",
  who: "WHO PARTICIPATES?",
  whopara:
    "Get several 20 minutes meetings with Prime Contractors/ OEMs, Key Govt/ Services officers dealing with procurement,  Manufacturers/ Suppliers/ Services Providers, Investors, buyers, supply chain managers, vendor managers, inventory controllers, project managers,  sales managers, sales engineers, R&D engineers... within different fields of the aerospace and defence industries.",
  why: "Why Participate?",
  whytitle: "SAVE EFFORT, SAVE TIME, SAVE MONEY",
  whypara:
    "DEFENCE PARTNERSHIP DAYS are a networking opportunity that will allow you to meet your peers in the MoD and Services and aerospace and defence industry and expand your professional network to generate business. These hard-to-organise meetings are pre-approved in order to save precious time. You can also obtain business intelligence by attending workshops and presentations in the parallel schedule.",
  whybtn: "Register",
  whopart: "WhO can Participate?",
  whoparapart:
    "Prime contractors, OEMs, manufacturers, suppliers, service providers, MSMEs, Start-ups, service providers and investors can all participate to interact amongst themselves and meet MoD and Services officers dealing with acquisition and procurement and to expand the boundaries of their business into the Defence sector. Casual visitors can also participate.",
  official: "Official partnerships",
  officialtitle:
    "Are you interested in becoming a Sponsor or an official partner?",
  officialpara:
    "If you are an association with over 300 members you should consider Assn sponsorship to get unmatched benefits for your member companies. If you are an online media with over 500,000  impressions each month, contact IMR now to  consider a partnership and benefit from visibility on all  communications related to  DEFENCE PARTNERSHIP DAYS 2024.",
  objkeyhead: "OBJECTIVES AND KEY BENEFITS",
  objkeyarray: [
    "DEFENCE PARTNERSHIP DAYS is a mvery focused B2G (Business to Government) and B2B (Business to Business) event with guaranteed face-to-face 1-to-1 meetings of 20 mins each, over two days.",
    "Industry participants will be able to meet all important officers in the Government and the Services who deal with acquisition, procurement, RFIs, RFPs, Indigenisation, Import Substitution, Make projects and so on. This otherwise would involve considerable time and investment by participants to organize on their own.",
    "Face-to-face meetings will help in clarity on projects and prospects of business and collaboration.",
    "Presentations and workshops in the parallel programme will also help in obtaining information on schemes and clarity in procedures and processes involved in doing business.",
    "The Government and the Armed Forces will partly achieving their goals of Indigenisation, getting solutions to Problem Statements, and increasing the pool of industry in the Defence Ecosystem by drawing in the huge untapped potential of capable, successful and technology–rich companies.",
    "The event will help in skill mapping and capability indexing of industry.",
  ],

  // Sponsorship categories
  categories: {
    "INDUSTRY SPONSORS": ["Platinum Sponsor", "Gold Sponsor", "Silver Sponsor"],
    "Association Sponsors": [
      "Strategic Association Sponsor",
      "Innovation Association Sponsor",
      "Technology Association Sponsor",
    ],
    "MANUFACTURERS & SUPPLIERS REGISTERED": [],
    STARTUPS: [],
    INVESTORS: [],
    "ASSOCIATES AND MEDIA PARTNERS": [],
    ORGANISERS: [],
  },

  // subscription package
  subscriptiontitle: "Subscription Packages",
  subscriptiontitlebold: "Subscription Packages",
  subtitlesub: "Basic",
  subtitleprice: "₹ 40,000",
  subtitlecontent:
    "Entry fee to DEEFENCE PARTNERSHIP DAYS 2024 for one person with benefit of fixing 10 pre-scheduled meetingsover two days. This Plan includes - ",
  subtitlecontentli: [
    "Your companyprofile page with products, services, presentation, brochures & video on the event website",
    "Your full page searchable company profile publishedin the Defence Industry Directory(for MoD and Services HQ) on CD and in print. Copy will be provided to participants.",
    "Your participation with contact details(6 lines) published in the EventGuide.",
    "Your pre-programmed and individual meetingschedule with selectedconfirmed participants",
    "Free accessto Central Talks,Presentations, Parallel Programme, Refreshments and Networking Lunch",
  ],
  subtitlebtn: "Get Started",

  // payment
  paymenttitle: "Make Payment",
  payment1: "Credit Card",
  payment2: "UPI",
  payment3: "PhonePe",

  // activate
  activatetitle: "Activate Account",
  activatedisc:
    "Your account has been created and requires an activation. An e-mail with an activation link and an activation code has been sent to your E-mail address. Please enter your activation code below to activate your account.",
  activatemail: "Activation Code on E-mail",
  activatemo: "Activation Code on Mobile Number",
  activatebtn: "Activate",

  // success
  successtitle: "Success!",
  successdisc:
    "Your account is successfully activated. Please click on next button to continue to your website. Thank you so much for joining.",
  successbtn: "Next",

  // login
  loginpoptitle: "LogIn",
  loginpopdisc: "Please enter your registered email id and password.",
  loginpopmail: "Email-ID",
  loginpopmailph: "Enter your email id here",
  loginpoppass: "Enter your password",
  loginpoppassph: "Enter your password",
  loginpopbtn: "Login to your account",

  // company profile input
  companyprofiletitle: "Company Profile Input",
  companyprofileblue: "Company Name",
  cpheading1: "My Package",
  cpheading1point1: "A1. Business Participant",
  cpheading1point2:
    "A2. Second Business Participant with 30-meeting Pack Co-participant",
  cpheading1point3: "A3. Co-participant",
  cpheading2: "My Add-ons",
  cpheading2point1:
    "C1. Second Business Participant with 30-meeting Pack Co-participant",
  cpheading2point2: "C2. Gold Sponsorship",
  cpheading2point3: "C3. Premium Booth 12 sqm",
  cpheading2point4: "C4. Silver Sponsorship",
  cpheading2point5: "D1. Registration Sponsor",
  cpheading2point6: "D2. Lanyard Sponsor",
  cpheading2point7: "D3. Delegate Folders/Bags Sponsor.",
  cpheading2point8: "D4. Name Badge Sponsor",
  cpheading2point9: "D5. Lunch Sponsor",
  cpheading2point10: "D6. High-Tea Sponsor",
  cpheading2point11: "D7. Refreshments Sponsor Day 1",
  cpheading2point12: "D8. Refreshments Sponsor Day 2",
  workshopTitle: "Conduct a Workshop",
  workshopTitleDisc:
    "You have selected the Conduct a Workshop option. Please fill in the details below to proceed with fulfilling the order:",
  workshopLabel1: "Workshop Topic",
  workshopLabel2: "Speaker’s name",
  speakerBio: "Speaker’s short biodata",
  speakerPhoto: "Upload Speaker’s photo",
  uploadSpeakerPhoto: "Upload speaker’s photo here",
  uploadSpeakerPhotosize: "(300 x 300, high-res JPG, PNG,  max 1 mb)",
  presentationFilePhoto: "Upload Presentation file here",
  presentationFilePhotosize: "(pdf only, maximum 25 mb)",
  presentationFile: "Upload Presentation file if offering for downloading",
  presentationTitle: "Make a Presentation",
  presentationTitleDisc:
    "You have selected the Conduct a Presentation option. Please fill in the details below to proceed with fulfilling your order.",
  cpheading2point14: "E2. Make a Flash Presentation",
  cpheading2point15: "E4. Standee Banner.",
  cpheading2point16: "E5. Large Banner",
  cpheading2point17: "E6. Literature Inserted in Delegate Bags",
  cpheading2point18: "E7. Full page Advt in Event Guide",
  cpheading2point19: "E8. Half page Advt in Event Guide",
  cpheading2point20: "E9. Back Cover Advt in Event Guide",
  cpheading2point21: "E10. Inside Front Cover Advt in Event Guide",
  cpheading2point22: "E11. Inside Back Cover Advt in Event Guide",
  cpname: "Maj Gen Ravi Arora",
  cpdesignation: "CEO, IMR Media Pvt Ltd",
  cppoint1: "B1. Strategic Assn Sponsor",
  cppoint2: "B2. Innovation Assn Sponsor",
  cppoint3: "B3. Technology Assn Sponsor",
  cppoint4: "B4. Platinum Sponsor",
  cppoint5: "B5. Gold Sponsor",
  cppoint6: "B6. Silver Sponsor",
  cpbtn: "Complete Your Profile",
  cparray: [
    "Main Business Participant",
    "Second Business Participant",
    "Co-Participant",
  ],
  cpdetails: [
    "Name",
    "Designation ",
    "Mob Number",
    "Email id",
    "Linkedin",
    "Twitter (X)",
    "Facebook",
    "Youtube",
  ],
  cptitle1input4: "Enter Company Video Youtube link",
  cptitle1: "About the Company",
  cptitle1input1: "Company Name",
  cptitle1input2: "Company Video (YouTube link)",
  cptitle1input3: "Company Description",
  cptitle1blue100: "(Max: 100 characters)",
  cptitle1blue1000: "(Max: 1000 characters)",
  cptitle1blue2000: "(Max: 2000 characters)",
  cptitle1uploadlogo: "Upload Logo Here",
  cptitle1uploadcomlogo1: "Upload Company Logo",
  cptitle1uploadcomlogo2: "Upload Company Brochure",
  cptitle1uploadlogod: "(jpg, png, max 1 MB, ratio 3:2)",
  cptitle1uploadlogod1: "(jpg, png, max 1 MB, ratio 16:9)",
  cptitle1uploadlogod2: "(One file, pdf, ppt, pptx, word,  max size 10 MB)",
  cptitle1uploadbrochure: "Upload Company Brochure Here",
  cptitle1uploadbrochured: "(One file, pdf, ppt, pptx, word,  max size 10 MB)",
  cptitle1uploadimage: "Upload Cover image for Brochure Here",
  cptitle1uploadimaged: "(jpg, png, size 1 MB,  9:16 ratio)",
  cptitle2: "Business Activities/ Products",
  cptitle2input1: "Main Business Activites",
  cptitle2input1ph: "Select Business Activites",
  cptitle2input2: " Description of Business Activity",
  cptitle2input3: " Skills/ Technical Sets",
  cptitle2input4: "Product/Services",
  cptitle2input4ph: "Select Product/Services",
  cptitle2input5: "Display Up to 4 Products",
  cptitle2input5uploadimage: "Upload Image Here",
  cptitle2input5uploadimaged: "(jpg, png, max size 1MB, 9:16 ratio)",
  cptitle2inout5uploadimagedisc: "Product Description Here (300 characters)",
  cptitle3: "Offers and Needs",
  cptitle3input1: "My Offers",
  cptitle3input2: "My Needs",
  cptitle4: "Clients Serviced",
  cptitle4input1: "Companies and Organisatons Names",
  cptitle5: "Contact Persons 2 (Non-participating)",
  cptitle5contact: ["First Contact", "Second Contact"],
  cptitle5contactinput1: "Name",
  cptitle5contactinput2: "Designation",
  cptitle5contactinput3: "Mobile No.",
  cptitle5contactinput3mo: "+91",
  cptitle5contactinput3ph: "Enter Mobile no.",
  cptitle5contactinput4: "Email ID",
  cptitle6: "Basic Company information",
  cptitle6input1: "Company Name",
  cptitle6input1ph: "Company Name",
  cptitle6input2: "Type of Company",
  cptitle6input2ph: "Type of Company",
  cptitle6input3: "Address",
  cptitle6input3ph: "Address",
  cptitle6input4: "City",
  cptitle6input4ph: "City",
  cptitle6input5: "Pin code/Zip code",
  cptitle6input5ph: "Enter 6 digit pincode",
  cptitle6input6: "State",
  cptitle6input6ph: "State",
  cptitle6input7: "Country",
  cptitle6input7ph: "--Select--",
  cptitle6input8: "GST No",
  cptitle6input8ph: "GST No",
  cptitle6input9: "Year of Inc/Est",
  cptitle6input9ph: "DD/MM/YYYYY",
  cptitle6input10:
    "Turnover (if required turnover may be  mentioned by different verticals)",
  cptitle6input11: "Parent Group/Company",
  cptitle6input12: "Tel",
  cptitle6input13: "Mobile",
  cptitle6input14: "Email",
  cptitle6input15: "Website",
  cptitle7: "Company Literature for Download",
  cptitle7d:
    "(Up to four items, For download by other  participants and visitors File type: pdf, ppt, pptx, jpg, png, or word, each max 5MB",
  cptitle7array: [
    "Upload Item1 Here",
    "Upload Item2 Here",
    "Upload Item3 Here",
    "Upload Item4 Here",
  ],

  // company profile
  cptitlemain: "Company Profile",
  cptitlemainblue: "Company Name",
  imagedcp1: "Company Name here",
  imagedcp2: "Corporate Video/ Presentation",
  imagedcp3: "Company Brochure",
  imagedcp3d:
    "DescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionDescriptionhereDescriptionDescriptionhereDescriptionDescriptionhereDescription",
  imagedcp3btn: "Download",
  mainparticipant: "Main Participant",
  mainbusinessparticipant: "Main Business Participant",
  secondparticipant: "Second Participant",
  secondbusinessparticipant: "Second Business Participant",
  coparticipant: "CO-Participant",
  participant: [
    "Name",
    "Designation ",
    "Mob Number (<> Show, <> Do not show to participants)",
    "Email id",
    "Linkedin",
    "Twitter (X)",
    "Facebook",
    "Youtube",
  ],
  participantbtn: "Request Meeting",
  participanttitle1: "Main Business Activities",
  participanttitle2: "Description of Business Activity",
  participanttitle2d:
    "DescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhere",
  participanttitle3: " Skills/ Technical Sets",
  participanttitle3d:
    "DescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhere",
  participanttitle4: "Products and Services",
  participanttitle4array: [
    "1. Product list1",
    "2. Product list1",
    "3. Product list1",
    "4. Product list1",
    "5. Product list1",
    "6. Product list1",
    "7. Product list1",
    "8. Product list1",
  ],
  participanttitle5: "Contact Persons (Non-participating)",
  participanttitle5subtitle: ["1. First contact", "2. Second contact"],
  participanttitle5subtitledetails: [
    "Name",
    "Designation",
    "Mob No  ",
    "Email Id",
  ],
  participanttitle6: "Basic Company Information",
  participanttitle6label1: "Company Name",
  participanttitle6input1: "Company Name",
  participanttitle6label2: "Company Type",
  participanttitle6input2: "Company Type",
  participanttitle6label3: "Address",
  participanttitle6input3: "Full Address",
  participanttitle6label4: "GST No.",
  participanttitle6inout4: "GST No.",
  participanttitle6label5: "Year of Inc/Est",
  participanttitle6inout5: "Year",
  participanttitle6label6: "Turnover",
  participanttitle6inout6: "Turnover",
  participanttitle6label7: "Parent/Group Company",
  participanttitle6inout7: "Parent",
  participanttitle6label8: "Tel",
  participanttitle6inout8: "Tel",
  participanttitle6label9: "Mobile",
  participanttitle6inout9: "Mobile",
  participanttitle6label10: "Email",
  participanttitle6inout10: "Email",
  participanttitle6label11: "Website",
  participanttitle6inout11: "Website",
  participanttitle7: "products  Details",
  participanttitle7array: [
    { name: "Product Name" },
    {
      desc: "DescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDes",
    },
  ],
  participanttitle8: "Your Offers",
  participanttitle8blue: "What Are You Offering to Interlocutors",
  participanttitle8d:
    "DescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhere",
  participanttitle9: "Your Needs",
  participanttitle9blue: "What Are You Seeking From Interlocutors",

  participanttitle10: "Clients Serviced",
  participanttitle9d:
    "DescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhereDescriptionhere",

  // login key govt
  loginkeyheaddisc:
    "Please complete your preliminary details here and follow it up with detailed profile buildup on the meetings pages for registered participants.",
  loginkeyheadbtn: "Build Full Profile",
  loginkeytitle: " KEY GOVT & SERVICES OFFICERS PROFILE INPUT",
  loginkeyblue: "Officer’s Name",
  loginkeyprofile: "Brig IM Smart",
  loginkeyprofilelight: "Brig (Indigenisation)",
  loginkeyprofilemini:
    "Dte of Indigenisation, IHQ of MoD (Army)  West Block-3,  Wing-5, 2nd Floor, RK Puram, New Delhi-66",
  loginkeycompletebtn: "Complete Your Profile",
  loginkeycard1: "Main Participant",
  loginkeycard2: "Substitute Participant",
  loginkeycard: [
    { name: "Name", disc: "Write Name " },
    { name: "Designation  ", disc: "designation" },
    {
      name: "Main Business Activites (select multiple)",
      disc: "Select Business Activites",
    },
    {
      name: "Product/Services (select multiple)",
      disc: "Select Product/Services",
    },
  ],
  loginkeycardKeyDashbord: [
    { name: "Name", disc: "Name of main business participant" },
    { name: "Designation  ", disc: "designation here" },
    {
      name: "Org/ Dep/ Dte/ Branch",
      disc: "Org/ Dep/ Dte/ Branch",
    },
    {
      name: "Service",
      disc: "Service",
    },
  ],

  loginkeycarduploaddisc: "Upload Participant’s Image Here",
  loginkeycard3: "About Your Branch/Dte",
  loginkeycard3label1: "Branch/ Dte Name",
  loginkeycard3label2: "Service/ Dept",
  loginkeycard4: "MAIN SUBJECTS OF RESPONSIBILITY",

  loginkeycard5: "List of Products/ Services Your Are Looking For",

  // abc dashbord
  abctitle: "ABC DASHBOARD",
  abcrounded1: "My Package",
  abcrounded1array: [
    "A1. Business Participant",
    "A2. Second Business Participant with 30-meeting Pack Co-participant",
  ],
  abcrounded2: "My Add-ons",
  abcrounded2array: [
    {
      title: "C1. Eco Booth 4sqm",
      disc: "Complete the requirements to fulfill your order.",
      btn: "Click Here",
    },
    { title: "C2. Gold Sponsorship" },
    {
      title: "D1. Registration Sponsor",
      disc: "Complete the requirements to fulfill your order.",
      btn: "Click Here",
    },
  ],
  abctitle2: "My Package",
  abctitle2array: [
    "A1. Business Participant",
    "A2. Second Business Participant with 30-meeting Pack Co-participant",
  ],
  abctitle2label: "Company Name",
  abctitle2blue: "(Max: 1000 characters)",
  abctitle2blueuploadlogo: "Upload Logo Here",
  abctitle2blueuploadlogodisc: "(jpg, png, max 1 MB, ratio 3:2)",
  abctitle2blueuploadbrochure: "Upload Company Brochure Here",
  abctitle2blueuploadbrochuredisc:
    "(One file, pdf, ppt, pptx, word,  max size 10 MB)",
  abctitle2blueuploadvideo: "Upload Company Video Here",
  abctitle2blueuploadvideodisc: "(jpg, png, max 1 MB, ratio 3:2)",
  abctitle3: "My add-ons",
  abctitle3label1: "No Add-ons",
  abctitle3label1disc:
    "You haven’t brought any add ons yet. Once you buy any add on its details will be shown here. Please click below button to buy add-ons",
  abctitle3label1btn: "Buy add-ons",
  abctitle3uploadarray: [
    { big: "Upload Logo Here", small: "(jpg, png, max 1 MB, ratio 3:2)" },
    {
      big: "Upload Company Brochure Here",
      small: "(One file, pdf, ppt, pptx, word,  max size 10 MB)",
    },
    {
      big: "Upload Company Video Here",
      small: "(jpg, png, max 1 MB, ratio 3:2)",
    },
  ],
  abctitle3label2: "Product/Services",
  abctitle3label2disc: "Select Product/Services",
  abcprofile: "Maj Gen Ravi Arora",
  abcprofiledisc: "CEO, IMR Media Pvt Ltd",

  reqmeetname: "Kemji Akou",
  reqmeetdesignation: "CEO",
  reqmeetdesignation1: "Org/ Dep/ Dte/ Branch  ",
  reqmeetname1: "Name here",
  reqmeetdesignation2: "Service",
  reqmeetname2: "Service here",
  reqmeetbtn: "Request Meeting",
  reqmeetlabel1input: "Branch/ Dte Name  here ",
  reqmeetlabel2input: "Service/Dept here ",
  reqmeetlabel3: "Main subjects of officer's Responsibility",
  reqmeetlabel3input: "Main subjects of Responsibility",
  reqmeetlabel4: "List of Product/Services officer is looking for",
  reqmeetlabel4input: "List of Product/Services you are looking for",

  // communication options
  communicationtitle: "Communications Options",
  communicationdisc:
    "Enhance your presence and influence through a number of options, indoor and outdoor, in print and through the Event Guide. Consider the following:",
  comoptiontitle: "Business Workshops",
  comoptiondisc1:
    "Hosting a workshop presents an unparalleled opportunity to companies for meaningful engagement and relationship-building with armed forces decision makers. Companies can showcase their expertise, products, and solutions in a focused and interactive setting.",
  comoptiondisc2:
    "Workshops allow you to delve deeper into your offerings, providing decision makers with a comprehensive understanding of how your products or services can address specific challenges faced by the armed forces. This hands-on approach fosters a more in-depth exploration of capabilities, features, and potential applications, leading to a richer exchange of insights and ideas.",
  comoptiondisc3:
    "Workshops offer a conducive environment for direct communication and collaboration between companies and decision makers, fostering a more personalized and impactful engagement.",
  comoptiontitlebluebg: "Organize a Workshop",
  comoptiontitlebluebgdisc:
    "As a Paricipant you can opt to organise a workshop. A 40-mins slot will be allotted to you during the Parallel Programme, subject to approval of your request and topic by the Organising Committee.",
  comoptiontitleblueformhead:
    "Please submit your request for conducting a workshop. IMR will revert with options:",
  comoptiontitleblueformph: ["Name", "Company", "Email", "Mobile No.", "Topic"],
  comoptiontitlebluebtn: "Submit",
  comoptiontitle2: "Presentations",
  comoptiontitle2disc1:
    "Presentations, despite their brevity, add significant value to companies' participation in Defence Partnership Days. These short, focused presentations offer a powerful platform for companies to quickly capture the attention of armed forces decision makers and convey key messages about their offerings.",
  comoptiontitle2disc2:
    "Presentations serve as impactful teasers, providing a snapshot of the company's capabilities, innovations, and value proposition. In just a few minutes, companies can highlight their unique selling points, recent achievements, and competitive advantages, sparking curiosity and generating interest among decision makers.",
  comoptiontitle2disc3:
    "Presentations enable companies to make a memorable impression in a brief timeframe. By delivering concise and compelling content, companies can effectively communicate their core messages and leave a lasting impact on the audience. This concise format also ensures that decision makers receive essential information without overwhelming them with excessive detail.",
  comoptiontitlebluebg2: "Organize a Presentation",
  comoptiontitlebluebgdisc2:
    "As a Paricipant you can opt to organise a workshop. A 40-mins slot will be allotted to you during the Parallel Programme, subject to approval of your request and topic by the Organising Committee.",
  Newsletter: "1. Newsletter Promo",
  Newsletterdisc1:
    "One news/product item with text and image (with url link) will be included in six event newsletters to all participants.",
  Newsletterdisc2:
    "Securing a prime promotional spot in 6 of our newsletters. As a featured company, you'll enjoy several advantages. Maintain a buzz around your brand with consistent outreach that keeps your audience engaged. Leverage the newsletter through our very large mailing list. Become a thought leader by sharing insights directly in the inboxes of your ideal clients.",
  Newsletterbtn: "Place Order",
  standee: "2. Standee Banner",
  standeedisc1:
    "One 3x6 ft standee banner, printed, frame mounted and displayed in the event venue/ refreshment area/ foyer/ meetings area.",
  standeedisc2: `Reserve your indoor standee banner space today! They are more than just eye-catching displays—they're powerful marketing tools that make a lasting impact. They  capture and hold attendees' attention. `,
  displaybig: "3. Display Large Banner outdoor at the venue",
  displaybigdisc1:
    "One large 6x8 ft banner displayed on approach road from main gate of Manekshaw Centre to the main building.",
  displaybigdisc2:
    "These large-scale advertisements are more than just visual attractions; they're smart investments in your brand's visibility and bottom line. They are made to capture attention, drive leads, and enhance your brand's impact. Secure your spot today!",
  displaybiggreen: "Item is successfully added into cart.",
  literature: "4. LitErature Inserted in Delegate FOLDERS",
  literaturedisc: "You have selected the Literature option. Please fill in the details below to proceed with fulfilling your order.",
  literaturedisc1:
    "One item of literature (max 12 pages) inserted in each Delegate Bag.",
  literaturedisc2:
    "Your literature isn't just paper—it's a gateway to connections, growth, and success! Long after the event, your literature remains in their possession. It's a lasting impression that outlives the conference.",
  fullpage: "5.  FULL PAGE ADVT IN EVENT GUIDE",
  fullpagedisc1: "Running page positioning.",
  fullpagedisc2:
    "Unlike fleeting digital ads, the Event Guide remains in delegates' possession. It's a tangible resource they'll revisit, ensuring your brand stays top of mind long after the event concludes. Use the Event Guide to showcase your products, services, and unique value proposition.",
  fullpagebtn: "Book Advt",
  frontinside: "6.  FRONT INSIDE COVER FULL PAGE ADVT IN EVENT GUIDE",
  frontinsidedisc:
    "Delegates often refer back to the Event Guide during and after the event. Your ad becomes a conversation starter—a reason for delegates to seek you out. Since there is only one Front Inside Cover, this placement offers exclusivity that can't be matched by interior ad pages, reducing competition for the reader's attention.",
  backcover: "7.  INSIDE BACK COVER FULL PAGE ADVT IN EVENT GUIDE",
  backcoverdisc:
    "The Event Guide reaches a highly targeted audience—delegates who are actively engaged in the event. Use it strategically to connect, network, and build relationships. The Event Guide is your gateway to targeted exposure, thought leadership, and meaningful connections!",
  backcoveradvt: "8.  BACK COVER ADVT IN EVENT GUIDE",
  backcoveradvtdisc:
    "This premium advertising space is one of the most visible and frequently seen parts of an Event Guide. A back cover ad can leave a lasting impression. This placement projects importance and prestige of the advertiser, enhancing the perceived value and credibility of the brand and product.",
  halfpage: "9.  HALF PAGE ADVT IN EVENT GUIDE",
  halfpagedisc:
    "Cost effective and it ensures every delegate sees your message. Companies which advertise in addition to their normal entries, show that they care about communicating with their target audience. Small ads can capture the readers imagination and make a strong impact.",

  // newsletterpopup
  newsskip: "skip",
  newsletterpopup: "Newsletters Promo",
  newsletterpopupdisc:
    "You have purchased the newsletter promo option. Please provide the details bellow to fulfill the order.",
  uploadArticle1: "Upload Article Image1 for IMR website (Optional)",
  uploadArticle2: "Upload Article Image2 for IMR website (Optional)",
  uploadArticleImage1: "Upload Article Image1 here",
  uploadArticleImage1Size: "(600 x 400 , JPG, PNG, upto 10 mb)",
  uplaodArticleImage2: "Upload Article Image2 here",
  uploadArticleImage2Size: "(600 x 400 , JPG, PNG, upto 10 mb)",
  newsletterupload: "Upload Company Banner",
  newsletteruploadblue: "Upload Company Banner Here",
  newsletteruploadbluesize: "(300 x 300 px JPG, PNG, upto 10 mb)",
  newsletterinputlabel: [
    "Text",
    "URL link to external article (optional)",
    "Article (if hosting required on IMR website. (optional))",
  ],
  newsletterinputdisc1: "(Max: 50 words)",
  newsletterinputdisc2: "(Up to 600 words)",
  newsletterimageupload: [
    {
      title: "Upload Article Image1 (Optional)",
      disc: "Upload Article Image1 Here",
    },
    {
      title: "Upload Article Image2 (Optional)",
      disc: "Upload Article Image2 Here",
    },
  ],
  newsletterbtn: "Purchase Newsletters Promo offer",
  standeepop: "Large Standee Banner Outdoor",
  stabdeeDisc: "Please fill in the details below to fulfill your purchase:",
  commonpopuploadtitle: "Upload creative banner",
  standeebtn: "Submit",
  standeeupload: "Upload Sponsor’s logo Here",
  standeesize: "(3 x 6 ft)",
  largebannerpopsize: "8x6 ft (WxH)",
  largebannerpop: "Large Banner outdoor",
  fullpageTitle: "Full Page Advt in Event Guide",
  fullpagesize: "(213mm W x 277mm H)",
  halfpagetitle: "Half Page Advt in Event Guide",
  halfpagesize: "(124mm H x 194mm W) Only Landscape orientation",
  backcoverpop: "Back Cover Advt in Event Guide",
  backcoversize: "(213mm W x 277mm H)",
  insidefrontpop: "Inside Front Cover Advt in Event Guide",
  insidefrontsize: "(213mm W x 277mm H)",
  insidebackpop: "Inside Back Cover Advt in Event Guide",
  insidebacksize: "(213mm W x 277mm H)",
  branding: "Branding Options",
  bookSponsorship: "Book Sponsorship",
  bookDay1: "Book for Day 1",
  bookDay2: "Book for Day 2",
  regiSponsor: "Registration Sponsor",
  regiSponsorDisc: [
    "Name and logo of sponsor company displayed on each Registration Counter.",
    "Make a positive impression at the first point of contact with attendees and get an exclusive boost for your brand and company name, with enhanced  visibility, reputation, and engagement",
  ],
  regiSponsorPrice: 10000000,
  lanyardSponsor: "Lanyard Sponsor",
  lanyardSponsorDisc: [
    "Logo of sponsor company in color on every Delegate Lanyard on both sides.",
    "Worn by attendees, speakers, and exhibitors throughout the event, your logo will be constantly visible, providing continuous exposure for your brand.",
  ],
  lanyardSponsorPrice: 10000000,
  delegateSponsor: "Delegate Folders/ Bags Sponsor",
  delegateSponsorDisc: [
    "Logo and name of company on each Delegate Folder.",
    "Enjoy high visibility, constant exposure with enhanced brand visibility among a captive audience. Enjoy appreciation of delegates and ongoing exposure for your brand long after the event has concluded.",
  ],
  delegateSponsorPrice: 20000000,
  nameSponsor: "Name Badge Sponsor",
  nameSponsorDisc: [
    "Logo of sponsor company on every Delegate Badge",
    "Every participant will use your branded badges, ensuring universal reach among event participants. This mobile presence ensures your brand reaches every part of the event.",
  ],
  nameSponsorPrice: 8000000,
  lunchSponsor: "Lunch Sponsor",
  lunchSponsorDisc: [
    "Banner outside Banquet Hall acknowledging sponsorship with logo and name.",
    "Enjoy positive association and appreciation of delegates as a generous and supportive participant in the industry. ",
  ],
  lunchSponsorPrice1: 12500000,
  lunchSponsorPrice2: 9000000,
  refreshmentSponsor: "Refreshments Sponsor",
  refreshmentSponsorDisc: [
    "Banner outside Banquest Hall acknowledging sponsorship with logo and name.",
    "A positive experience and sumptuous high-tea fosters brand loyalty among delegates and demonstrates your support for the industry and commitment to the attendees' overall experience.",
  ],
  refreshmentSponsorPrice1: 8000000,
  refreshmentSponsorPrice2: 6000000,
  brandbtn: "Sponsorship",
  brandbtn1: "Book for Day 1",
  brandbtn2: "Book for Day 2",
  regispopop: "Registration Sponsor",
  regispodisc: "Please provide the details below to fulfill the sponsorship:",
  regispopoplabel1: "Sponsor’s Name",
  lunchspopoplabel1: "Sponsor’s Name (as it will appear on the banner)",
  regispopoplabel2: "Upload Sponsor’s logo ",
  regispopopupload: "Upload Sponsor’s logo Here",
  regispopopuploadSize: "(16:9 ratio, high~ res JPG, PNG max 25 mb)",
  lanyard: "Lanyard Sponsor",
  delegatepop: "Delegate Folders/Bags Sponsor",
  namesponsor: "Name Badge Sponsor",
  lunchpop1: "Lunch Sponsor for Day 1",
  lunchpop2: "Lunch Sponsor for Day 2",
  refreshment1: "Refreshment Sponsor for Day 1",
  refreshment2: "Refreshment Sponsor for Day 2",
  exhibitionDoItLaterBtn: "Save & Exit",
  exhibitionSubmitBtn: "Submit",
  noteBold: "Note:",
  noteLine: " You may complete the requirement from your profile page later.",

  // company profile
  comproline:
    "To view full profile and to request for meetings, you must be registered and logged in.",
  comprobtn: "Register",
  comprotitle: "COMPANY Name",
  comprodisc:
    "Company description here Company description here Company description here Company description here Company description here Company description here Company description here Company description here",
  comprobrochurebtn: "Company Brochure",
  comproreqmeetlabel1: "Main Business Activities",
  comproreqmeetlabel2: "Product/Services",
  comproreqmeetlabel1input: "Selected category from drop down",

  // contact us
  contacttitle: "Contact Us",
  contactformline: "For any queries or information Please ",
  contactformtitle: "get in touch with us",
  contactforminput1: "Name*",
  contactforminput2: "Email Address",
  contactforminputMobile_number: "Mobile Number",
  contactforminput3: "Type message here",
  contactformlabel: "Type the character here:",
  contactformcaptcha: "Type captcha",
  contactformcaptchabtn: "Submit",
  contactformbtn: "Submit",
  contactUltitle: "Get in Touch",
  contactperson: "Indronil Banerjee",
  contactnumber: "+91 9582649664",
  contactmail: "dpd@defencepartners.in",
  contactaddress:
    "IMR Media Pvt Ltd  8A Ashok Marg, Silokhra  Gurgaon 122001, Haryana  India",
  contactnumber2: "+91-124-2380657, 2383657",
  contactCIN: "U22210DL2011PTC227474",
  contactGST: "06AACCI7766G1ZD0",
  catpcha: "7tm39v",

  // sticky navbar hover
  about1: "CDS' Message",
  about2: "Who Participates",
  about3: "Objectives & Key Benefits",
  about4: "How it Works",
  about5: "Participants & Partners",
  about6: "Newsletter",
  about7: "Exhibition Booths And Meeting Points",
  about8: "venue",

  partycipate1: "Key Govt/ Services Officers",
  partycipate2: "Casual Visitors",
  partycipate3: "Sponsors",
  partycipate4: "Prime Contractors/OEMs",
  partycipate5: "Manufacturers/Suppliers",
  partycipate6: "Start-ups",
  partycipate7: "Investors",
  partycipate8: "Associates and Media Partners",

  schedule1: "Full Schedule",
  schedule2: "Presentation &  Workshops",

  member1: "Log In/ My Profile",
  member2: "Guidelines for Participants",
  member3: "Request Meetings",
  member4: "Download Company Brochures",
  member5: "Download Presentations",

  pricing1: "Meetings Package",
  pricing2: "All Packages/ Options",
  pricing3: "Sponsorship Options",
  pricing4: "Exhibition Options",
  pricing5: "Branding Options",
  pricing6: "Communication Options",

  // participant's profile
  primetitle: "Prime Contractors/OEMs",
  startupTitle: "START-UPS PROFILES",
  investorProfileTitle: "INVESTORS PROFILES",
  manufacturerProfileTitle:
    "MANUFACTURERS/ Suppliers/ Service Providers PROFILES",
  mediaTitle: "Associates And Media Partners",
  corporateSponsorTitle: "CORPORATE SPONSORS",
  platinumSponsor: "Platinum Sponsors",
  goldSponsor: "Gold Sponsors",
  associationSponsorTitle: " ASSOCIATION SPONSORS",
  strategicSponsor: "Strategic Association Sponsor",
  innovationSponsor: "Innovation Association Sponsors",
  technologySponsor: "Technology Association Sponsors",
  primedisc:
    "Please visit the Participant’s Profiles listed below and request for direct face-to-face meetings  wherever you find the profile suits your requirements.  You may also use the Search function, by Company  Name, By Product, By Services or By Skill/ Technology  to see a suggested short list of Participants with whom  you may like to schedule your meetings.  Your package allow you to schedule 10 meetings. In  case you need to schedule more than 10 meetings, you  may buy an additional pack.",
  primebtn: "Buy Additional Meeting Pack",
  primesearchline: "Search Investor’s profiles",
  primesearchlabel: "Search",
  primesearcharray: [
    "All profiles",
    "By Company",
    "By Business Activity",
    "By Product/Services",
    "By Keyword",
  ],
  primecommontitle1: "Defence Public Sector Undertakings",
  primecommonview: "View Full Profile",
  primecommonreqmeet: "Request Meeting",
  primecommoncompotitle1: "Hindustan Aeronautics Ltd (HAL)",
  primecommoncompotitle2: "Bharat Electronics Ltd (BEL)",
  primecommoncompotitle3: "Bharat Dynamics Ltd (BDL)",
  primecommoncompotitle4: "Mazagon Dock Ltd (MDL)",
  primecommoncompotitle5: "Cochin Shipyard Ltd (CSL)",
  primecommoncompotitle6: "Bharat Electronics Ltd (BEL)",
  primecommoncompotitle7: "Bharat Dynamics Ltd (BDL)",
  primecommoncompotitle8: "Mazagon Dock Ltd (MDL)",
  primecommoncompotitle9: "Hindustan Aeronautics Ltd (HAL)",
  primecommoncompotitle10: "Munitions India Limited (MIL)",
  primecommoncompotitle11: "Armoured Vehicles Nigam Limited (AVANI)",
  primecommoncompotitle12:
    "Advanced Weapons and Equipment India Limited (AWEIL)",
  primecommoncompotitle13: "Troop Comforts Limited (TCL)",
  primecommoncompotitle14: "Yantra India Limited (YIL)",
  primecommoncompotitle15: "Munitions India Limited (MIL)",
  primecommoncompotitle16: "Armoured Vehicles Nigam Limited (AVANI)",
  primecommoncompotitle17: "Bharat Heavy Electrical Ltd (BHEL)",
  primecommoncompotitle18: "Electronics Corporation of India Ltd (ECIL)",
  primecommontitle2: "Central Public Sector Undertakings",
  primecommontitle3: "Prime Contractors/OEMs",
  primecommoncompotitle19: "Bharat Heavy Electrical Ltd (BHEL)",
  primecommoncompotitle20: "Electronics Corporation of India Ltd (ECIL)",
  primeseebtn: "See all",
  copyright: {
    title: "Copyright Policy",
    link: "IMR Media Pvt Ltd – ",
    weblink: "www.defencepartners.in",
    discription:
      "This copyright policy sets out how users are permitted to use our content and also explains the types of use that require the purchase of additional licences. This policy forms part of our terms and conditions. Thank you for reading this policy. We reserve the right to change our copyright policy from time to time by publishing an updated policy on www.defencepartners.in which shall become effective and replace any previous policy with effect from publication. This version of the copyright policy was published on 1 May 2020.",
    question1: "What is copyright?",
    answer1:
      "Copyright law gives the copyright owner the exclusive right to control the use of copyright protected works. All of the material published on our website and other digital/wireless platforms is protected by copyright law and should only be used as set out in the “How may I use Defencepartners.in content?” section below. Use that is made without our permission may therefore infringe our copyright which can result in personal and corporate liability. Where we state below that something is not allowed or permitted, then to do so is a breach of our terms and conditions: that is, it is a breach of contract, and may also violate copyright law.",
    question2: "How may I use deefencepartners.in content?",
    answer2:
      'All IMR Content belongs to IMR or its licensors who own all intellectual property rights (including copyright and database rights) in the IMR Content and any selection or arrangement of the IMR Content. No intellectual property rights in any Content are transferred to you. You may not use "Defence Partnership Days"  for any purpose of your own events or publication.',
    following: "You may do the following:",
    followingArray: [
      "View our content for your personal use on any device that is compatible with www.defencepartners.in (this might be your PC, laptop, smartphone, tablet or other mobile device) and store our content on that device for your personal use;",
      "Share links to articles by using any sharing tools we make available.",
    ],
    question3:
      "How may I republish or redistribute defencepartners.in content?",
    answer3:
      "As long as you do not create a Substitute, you may Publish online, the your participation in Defence Partnership Days ewvent.",
    copyrightSoftwareTitle: "Copyright Over Software",
    copyrightSoftwareDisc:
      "Copyright in any software that is made available for download from www.defencepartners.in belongs to IMR. Your use of the software is governed by the terms of any licence agreement that may accompany or be included with the software. Do not install or use any of this software unless you agree to such licence agreement. IMR is not responsible for any technical or other issues that may happen if you download third party software.",
    followingInformation:
      "If you are notifying IMR MEDIA PVT LTD of alleged copyright infringement, please be sure to provide the following information:",
    followingInformationArray: [
      "A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;",
      "A description of the copyrighted work claimed to be infringed, or, if multiple copyrighted works are covered by a single notification, a representative list of such works;",
      "A description of material that is claimed to be infringing and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;",
      "Information reasonably sufficient to permit us to contact you, the complaining party, such as an address, telephone number, and/or an electronic mail address;",
      "A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, or its agent, or the law;",
      "A statement by you that the information in the notification is accurate, and under penalty of perjury, that the complaining party has the authority to enforce the copyrights that are claimed to be infringed.",
    ],
  },

  privacyPolicy: {
    title: "Privacy Policy",
    subtitle: "IMR MEDIA PVT LTD",
    discription:
      "IMR Media Pvt Ltd. and its subsidiaries and affiliates (collectively “IMR Media,” “us,” “we,” or “our”) are committed to protecting your privacy and providing you with a positive experience while using our websites, products, or services on www.defencepartners.in (“Platform”) on.",
    discription2:
      "IMR Media reserves the right, at any time, to modify this Privacy Policy. If we make revisions that change the way we collect, use, or share personal information, we will post those changes in this Privacy Policy. If we make material changes to our Privacy Policy, we may also notify you by other means prior to the changes taking effect, such as by posting a notice on our Platform or sending you a notification. You should review this Privacy Policy periodically so that you keep up to date on our most current policies and practices. We will note the effective date of the latest version of our Privacy Policy at the end of this Privacy Policy.",
    scope: "1. Scope",
    scopeDisc1:
      "This Policy applies to IMR Media Platform (defencepartners.in) and provides you with guidance on your rights and obligations in relation to your personal information.",
    scopeDisc2:
      "IMR Media’s Privacy Policy (the “Privacy Policy”) establishes and communicates the key principles that IMR Media follows in protecting the personal information that we collect. Please note that some privacy rights and obligations may differ in certain locations based on local law, in which case IMR Media will comply with the local legal requirements. To the extent a notice provided at the time of collection from a website or specific product conflicts with this Privacy Policy, such specific notice or supplemental privacy statement will control.",
    collectionPI: "2. Collection of Personal Information",
    collectionPIDisc1:
      "“Personal information” is any information that can be used to identify an individual, and may include things such as your name, physical address, email address, phone number, login information, marketing preferences, company affiliation, geographic location, or payment card information. IMR Media may collect personal information that is necessary for legitimate business purposes, which will be disclosed to you at the time of collection. IMR Media will use this information for the purposes for which it was collected. IMR Media may also collect personal information from trusted third-party sources and engage third parties to collect personal information to assist us.",
    collectionPIDisc2:
      "One of the most common methods of collecting personal information is during the online form submission or registration process, including where a website user is requesting a free trial or a courtesy newsletter, registering for an event, or responding to similar offers such as sample reports, white papers, or further product information.",
    collectionPIDisc3:
      "In some instances, IMR Media and the third parties we engage may automatically collect and aggregate data using cookies, weblogs, web beacons, and other similar applications. This information is used to better understand and improve the usability, performance, and effectiveness of our Platform and to help us tailor content or offers for you. Please read the “Cookies” section below for more information.",
    purpose: "3. Purpose and Use of Personal Information",
    purposeDisc1:
      "IMR Media may use your personal information for operational, legal, administrative, and other legitimate purposes permitted by applicable law. Some of the ways we may use personal information include:",
    purposeDisc1Array: [
      "a. Providing you with requested products or services.",
      "b. Providing you with information regarding similar products or events.",
      "c. Analyzing and monitoring extent of use.",
      "d. Providing customized product and service information.",
      "E. Allowing users to participate voluntarily in mailings or other events.",
      "f. Providing product service updates, information, and alerts.",
      "g. Sending communications, including for marketing or other customer satisfaction purposes.",
      "h. Order processing, and to provide transaction documents.",
      "i. To contact users for information verification purposes.",
    ],
    rightPI: "4. Right to Access and Correct Personal Information",
    rightPIDisc1:
      "We strive to make sure that our information is reliable, accurate, and up-to-date. While personal information is maintained by IMR Media, you may access your personal information to the extent required by law to review, update, and correct inaccuracies. Upon request made to the contact listed below, we will provide you with reasonable access to the personal information we collect about you. Because personal information-for example, your email address-is required to use the Platform, we retain personal information as long as your account is active. You will have the opportunity to correct, transfer, update, modify, or delete this information by logging into your account and updating your information online. You may also limit the use and disclosure of your information by either unsubscribing from marketing communications or contacting us at the address listed below under the section titled “Contact Information” or by emailing contactus@imrmedia.in. Please note that some information may remain in our records even after you request deletion of your information, to the extent permitted by the Privacy Shield Principles. Additionally, there may be limits to the amount of information we can practically provide. For example, we may limit access to personal information where the burden or expense of providing access would be disproportionate to the risks to an individual’s privacy or where doing so would violate others’ rights.",
    sharing: "5. Sharing Personal Information",
    sharingDisc1:
      "IMR Media may disclose personal information to its parent, subsidiary, affiliates, and other related companies. IMR Media may disclose personal information to service providers for the purposes of operating our business, delivering, improving, and customizing our products or services, sending marketing and communications related to our business, payment processing, and for other legitimate purposes permitted by applicable law or otherwise with your consent. Personal information handled by these service providers is subject to their terms of use and privacy policies. Please review the service provider’s terms of use and privacy policies to better understand how they manage your personal information. IMR Media is responsible for the processing of personal data it receives under the Privacy Shield Framework and subsequently transfers to a third party acting as an agent on its behalf. IMR Media complies with the Privacy Shield Principles for all onward transfers of personal data from the EU, including the onward transfer liability provisions.",
    sharingDisc2:
      "There may also be instances when IMR Media may disclose your personal information without providing you with a choice in order to honour a request that you have made through the products or services; comply with the law or in response to a court order, government request, or other legal process; protect the interests, rights, safety, or property of IMR Media or others; protect against fraud or for risk management purposes; or respond to adverse third parties (i.e., adverse parties and their counsel and other agents) in the context of litigation. In addition, if IMR Media sells all or part of its business or makes a sale or transfer of assets or is otherwise involved in a merger or business transfer, you agree that IMR Media may transfer your personal information to a third party as part of that transaction.",
    sharingDisc3:
      "In all cases IMR Media requires all unaffiliated third parties to treat personal information as confidential. IMR Media will not provide personal information to third parties for their own marketing purposes without your consent.",
    sharingDisc4:
      "If you participate in a discussion forum, chat room, or provide comments related to IMR Media on a social media or other public platform, you should be aware that the information you provide there (i.e., your public profile) will be made broadly available to others, and could be used to contact you, send you unsolicited messages, or for purposes neither IMR Media nor you have control over. Also, please recognize that social media forums and chat rooms not controlled by IMR Media may have additional rules and conditions. IMR Media is not responsible for the personal information or any other information you choose to submit on these forums that are not IMR Media controlled.",
    sharingDisc5:
      "The Platform may offer you the ability to share personal information through a social networking site (e.g., Facebook, Twitter), using such site’s integrated tools (e.g., Facebook “Like” button, or Twitter “Tweet” button). The use of such integrated tools enables you to share personal information about yourself with other individuals or the public, depending on the settings that you have established with such social networking site. For more information about the purpose and scope of data collection and use in connection with such social networking site or a site’s integrated tools, please visit the privacy policies of the entities that provide these social networking sites.",
    security: "6. Security of Personal Information",
    securityDisc1:
      "IMR Media has physical, administrative, and technical safeguards in place to help protect the personal information IMR Media collects. While there is no such thing as perfect security on the Internet, we will take reasonable and appropriate steps to ensure the safety of your personal information.",
    securityDisc2:
      "Information Management:  The event platform may involve third-party services; participants are subject to the terms and conditions of those services. By registering to the event, each participant authorizes IMR Media Pvt Ltd to use his email address(es) and mobile numbers, for dispatching information regarding the event and promotion of other events organized by IMR Media Pvt Ltd. These details may also be transmitted to the organizing partners, This information will allow informing the participants about upcoming events and receiving information by email; with personalized communication including sending newsletters, special offers and special emails within the communication framework of the event. Moreover, IMR Media Pvt Ltd reiterates that if the participant changes his mind and no longer wishes to receive certain categories of emails, he can at any moment contact us in order to keep us informed about these mailings.",
    retention: "7. Retention of Personal Information",
    retentionDisc1:
      "IMR Media will retain your personal information as needed to fulfill the purposes for which it was collected. We will retain and use your personal information as necessary to comply with our business requirements, legal obligations, resolve disputes, protect our assets, and enforce our agreements.",
    cookies: "8. Cookies",
    cookiesDisc1:
      "When visiting an IMR Media Platform, our servers may query your computer to see if there are cookies previously set by IMR Media. A cookie is a text file that is created when your browser visits a particular website, such as an IMR Media website. Afterwards, every time you visit the IMR Media website, your browser retrieves and sends this file to the IMR Media website’s server. Cookies enhance the website user experience by:",
    cookiesDisc1Array: [
      "a. Helping IMR Media authenticate you as a user when you log in to a secure area of the website.",
      "b. Storing information about your website user activities so you may easily pick up where you left off on the IMR Media server’s pages.",
      "c. Storing your user preferences, which allows you to customize how information is presented through site layouts or themes.",
      "d. Personalizing the manner in which IMR Media responds to you via marketing communications.",
    ],
    cookiesDisc2:
      "The cookies IMR Media collects may include the following information: a unique identifier, user preferences, and profile information used to personalize the content shown to the individual users. It is your choice whether you allow IMR Media to store such information on your computer or device. You may be able to set your web browser to alert you when a cookie is being used, and may use your browser to accept or reject the cookie; additionally, you may also disable the cookie feature on your browser entirely. However, disabling cookies may prevent you from using some features on IMR Media websites. You can find more detailed information about cookies and how they work at AboutCookies.org.",
    cookiesDisc3:
      "We may collect and share the physical location of your device with your consent for purposes consistent with this Privacy Policy, such as to provide you with personalized location-based products or services. In some instances, you may be permitted to allow or deny such use of your device’s location, but if you choose to deny such use, we may not be able to provide you with the applicable personalized products or services.",
    cookiesDisc4:
      "Some web browsers and devices permit you to broadcast a preference that you not be “tracked” online. At this time, IMR Media does not modify your experience based upon whether such a signal is broadcast.",
    cookiesDisc5:
      "IMR Media participates in interest-based advertising. This means that a third party may use cookies or similar technologies to collect information about your use of our products and services so that they can provide advertising about our products and services tailored to your interests. This advertising may appear either on our website, or on other sites.",
    cross: "9. Cross Border Transfer of Personal Information",
    crossDisc1:
      "As IMR Media works globally, we may transfer your personal information to affiliates, or to third parties as described above that are located in various countries around the world. By using our websites or products, or providing any personal information to us, where applicable law permits, you consent to the transfer, processing, and storage or such information outside of your country of residence where data protection standards may be different.",
    crossDisc2:
      "IMR Media safeguards and enables the global transfer of personal information in a number of ways:",
    shield: "10. EU-US Privacy Shield",
    shieldDisc1:
      "IMR Media is responsible for the processing of personal data it receives under the EU Privacy Shield Framework and subsequently transfers to a third party acting as an agent on its behalf. IMR Media complies with the Privacy Shield Principles for all onward transfers of personal data from the EU, including the onward transfer liability provisions. In certain situations, IMR Media may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.",
    shieldDisc2:
      "If you have any complaints regarding our compliance with this Privacy Policy, you should first contact us at the address listed below under the section titled “Contact Information” or by emailing contactus@imrmedia.in. We will investigate and attempt to resolve complaints and disputes regarding collection, use, and disclosure of personal information in accordance with this Privacy Policy.",
    prospective: "11. Prospective Employee and Employee Information",
    prospectiveDisc1:
      "If you submit an application for employment to or are currently an employee of IMR Media, IMR Media is authorized to collect the information in any form it obtains through your application for employment or continued employment with IMR Media, and your submission of employee related documents, including but not limited to the personal information that you submit in connection with your employment or intended employment (collectively “Employee Information”). Examples of Employee Information collected in connection with your employment or application for employment includes but is not limited to your name, date of birth, government issued identification (passport, social security number, driver’s license number), banking information, marital status, health information, benefit information, title, employment history, education, job qualifications, and other relevant employee personal data. We also collect some of this information about your dependents when you provide it to us when you enrol in health and other benefits or provide contact information. By providing personal information about family members or other individuals, you represent that you have their consent to provide this information to us for the purposes set forth in this Privacy Policy. Employee Information does not include any information that is available to the general public and excludes information generally available on a business card.",
    prospectiveDisc2:
      "IMR Media will collect, use, store and retain, and secure your social security number in accordance with this Privacy Policy. IMR Media will not intentionally communicate or make available, in any manner, your social security number to the general public. IMR Media will not print your social security number on any card required for you to access colleague member services provided by IMR Media. IMR Media will not require you to transmit your social security number over the Internet, unless the connection is secure or the social security number is encrypted. IMR Media will not print your social security number on any materials that are mailed to you, unless state or federal law requires or permits the social security number to be on the document to be mailed.",
    prospectiveDisc3:
      "In accordance with applicable law, IMR Media may use Employee Information to evaluate applications for employment, manage work-related aspects of the employee-employer relationship (i.e., training, payroll, benefits, travel, reimbursement expenses, performance appraisals, succession planning, team member health and safety), investigate and respond to any claims related to your employment, and comply with applicable laws and regulations. Your Employee Information will not be used for direct marketing purposes by us or any third party service provider, unless you specifically consent to such use.",
    prospectiveDisc4:
      "IMR Media shares your Employee Information with third parties who provide outsourced human resource functions to us. Those third parties are required to protect your Employee Information. IMR Media may disclose your Employee Information if required or permitted to do so by law (such as when part of a governmental agency action or litigation), governmental or quasi-governmental requests, or a regulatory organization, or to relevant third parties such as site technicians, brokers, auditors, lawyers, or professional advisors. In the event that IMR Media considers it necessary or where required by law to disclose Employee Information in certain situations where the health, safety, or wellbeing of a team member is at imminent risk, we may share your Employee Information with our outside lawyers, law enforcement or others who are legally authorized. As IMR Media continues to develop our business, we may buy or sell assets. In the unlikely event that IMR Media or substantially all of our assets are acquired, Employee Information may be one of the transferred assets.",
    prospectiveDisc5:
      "Employee Information may be stored in hard copy or electronic format within your branch office or in the cloud in the United States or other countries where IMR Media, its affiliates, agents, or contractors conduct business. Your Employee Information may be transferred into our human resources systems and used to manage your employment, may become part of your personnel file, and may be used for other work-related purposes. Your Employee Information will be kept for as long as is legally required, and in some instances longer depending upon the needs of the organization and industry practices.",
    prospectiveDisc6:
      "If you have any complaints regarding our compliance with this Privacy Policy, you should first contact us at the address listed below under the section titled “Contact Information” or by emailing contactus@imrmedia.in. If you are a citizen of the European Union and you have an unresolved concern regarding your Employee Information that we have not addressed satisfactorily, please contact your local data protection authority.",
    contractual: "12. Other Contractual Relationships",
    contractualDisc1:
      "If you enter into a separate contractual relationship with IMR Media which requires, or contemplates, collecting, using, or sharing information about you in a manner that is different than that which is described in this Privacy Policy, the terms of that agreement will apply.",
    website: "13. Other Websites",
    websiteDisc1:
      "This Privacy Policy does not apply to sites or applications offered by other companies or individuals, including third party products and services, that may be displayed as content in a search on our website. IMR Media encourages you to read the privacy policy of any third party site or applications before transmitting personal information.",
    contactInfo: "14. Contact Information",
    contactInfoDisc:
      "If you have any questions or concerns related to this Privacy Policy, please contact the IMR Media Chief Privacy Officer:",
    contact: [
      "IMR Media",
      "8A Ashok Marg, Silokhra",
      "Gurgaon 122001, India",
      "Attn: Chief Privacy Officer",
      "contactus@imrmedia.in",
    ],
    contactLine:
      "If IMR Media needs, or is required, to contact you concerning any event that involves information about you we may do so by email, telephone, or mail.",
    date: "Updated 1 August 2024.",
  },
  refundPolicy: {
    subtitle: "IMR Media Pvt Ltd",
    title: "Refund Policy - defencepartners.in",
    disc1:
      "If any of the Business Meetings Packages (A1, A2, A3, A4) are purchased and cancelled before 15 Aug, we will refund the whole amount after deducting Rs 2000 and 10% of the purchase amount. Thereafter, no refunds are possible for Business Meetings Packages.",
    disc2:
      "For all other options (Sponsorship, Exhibition, Branding, and Communications), refunds will be made up to 15 Sep 2024 after deducting 25% of the total cost of the bill. Thereafter, no refunds are possible, except in case the event is cancelled by the Organisers.",
  },
  shippingPolicy: {
    subtitle: "IMR Media Pvt Ltd",
    web: "www.defencepartners.in",
    title: "Shipping Policy",
    disc: [
      "Defence Partnership Days is a physical event and all products are of participation in nature being sold online.",
      "There is nothing to ship physically to customers.",
      "All items requiring shipping like Information Brochures, Delegate Passes, etc will be sent by IMR Media Pvt Ltd with No returns required.",
    ],
  },
};

export default string;
