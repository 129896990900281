import React from 'react'
import downlaod from './../../assets/presentationDownload.png'

const Presentation = ({ img, companyName, disc }) => {
    return (
        <div className='space-y-2'>
            <div className='relative h-60 xl:h-80 w-auto rounded-2xl overflow-hidden'>
                {img ? (
                    <img src={img} alt="brochure" />
                ) : (
                    <div className='w-full h-full bg-iconborder/25'></div>
                )}
                <a
                    // downlaod
                    className="flex justify-between items-center rounded-full backdrop-blur-xl bg-black/10 backdrop-opacity-100 p-4 absolute top-1/2 right-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-14"
                >
                    <img src={downlaod} alt="" className='w-full' />
                </a>
            </div>
            <div className='text-iconborder text-xs lg:text-sm'>{companyName}</div>
            <div className='text-sm md:text-base lg:text-lg xl:text-xl'>{disc}</div>
        </div>
    )
}

export default Presentation
